export const SET_ENIGME = 'SET_ENIGME';
export const ADD_LOCAL_MESSAGE = 'ADD_LOCAL_MESSAGE';
export const SET_TYPING = 'SET_TYPING';

export const setEnigme = enigme => ({
  type: SET_ENIGME, 
  enigme
});

export const setTyping = (typing, user = "emma") => ({
  type: SET_TYPING, 
  typing, 
  user
});

export const sendLocalMessage = (message, type) => ({
  type: ADD_LOCAL_MESSAGE, 
  message,
  message_type: type
});