export const SET_USER = 'SET_USER';
export const SET_LOADING = 'SET_LOADING';
export const SHOW_LOGIN = 'SHOW_LOGIN';
export const SET_ASK_MAIL = 'SET_ASK_MAIL';
export const SHOW_FIRST_LOOP = 'SHOW_FIRST_LOOP';

export const setUser = user => ({
  type: SET_USER, 
  user
});

export const setLoading = val => ({
  type: SET_LOADING, 
  val
});

export const setAskMail = val => ({
  type: SET_ASK_MAIL, 
  val
});

export const showLogin = val => ({
  type: SHOW_LOGIN, 
  val
});

export const showFirstLoop = (val) => ({
  type: SHOW_FIRST_LOOP, 
  val
});