import {SET_USER, SET_LOADING, SET_ASK_MAIL, SHOW_LOGIN,SHOW_FIRST_LOOP} from "./actions"

const initialState = {
  user: null,
  loading:true,
  first_loop_ask_email:false,
  showLogin:null,
  showFirstLoop:false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.user };
    case SET_LOADING:
      return { ...state, loading: action.val };
    case SHOW_FIRST_LOOP:
      return { ...state, showFirstLoop: action.val };
    case SHOW_LOGIN:
      return { ...state, showLogin: action.val };
    case SET_ASK_MAIL:
      return { ...state, first_loop_ask_email: action.val };
    case SET_LOADING:
      return { ...state, loading: action.val };
    default:
      return state;
  }
};