import {SET_ENIGME, ADD_LOCAL_MESSAGE, SET_TYPING} from "./actions"

const initialState = {
  enigme:null,
  localMessages:[],
  typing:false,
  typingSource:"emma"
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ENIGME:
      return { ...state, enigme: action.enigme };
    
    case SET_TYPING:
      return { ...state, typing: action.typing, typingSource: action.user};
    
    case ADD_LOCAL_MESSAGE:
      const {message, message_type} = action
      return { 
        ...state, 
        localMessages: [
          ...state.localMessages, 
          {
            content: message,
            type: action.message_type,
            date: Date.now()
          }
        ]
      };
      
    default:
      return state;
  }
};